import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.stock?.[0]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.stock?.[2]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.stock?.[5]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.stock?.[3]}")` }}
        ></figure>
        <div className="z-10 relative md:pt-[350px] pt-[280px] pb-[150px] w-4/5 mx-auto">
          <div className="w-full flex flex-col justify-center items-center">
            <h1 className="text-white text-center">
              {rpdata?.dbSlogan?.[1].slogan}
            </h1>
            <p className="text-white text-center">{rpdata?.dbValues?.[1].description}</p>
            <div className="flex justify-center md:block">
              <ButtonContent />
            </div>
            <div className="flex justify-center">
              <img src="https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/archivos_varios%2Fsello2%20google.png?alt=media&token=2d90f365-dfbe-487d-ac76-8805ef3aff81" alt="google_certification" className="w-[300px]" />
            </div>
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
